import { ILinkProps, Link } from '@fluentui/react';
import { ReactElement } from 'react';
import './index.scss';
export interface ILinkButton {
  children: string | ReactElement;
  onClick?: ILinkProps['onClick'];
  className?: string;
}

export const LinkButton = (props: ILinkButton) => {
  const { children, onClick } = props;
  return (
    <Link className={`onix-link-button ${props.className ?? ''}`} onClick={onClick}>
      {children}
    </Link>
  );
};
