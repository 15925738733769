import { IModalProps } from '@fluentui/react'
import { Dialog as DialogFluent, DialogFooter, IDialogContentProps } from '@fluentui/react/lib/Dialog'
import { ReactElement, ReactNode, useCallback, useEffect, useState } from 'react'
import "./Dialog.scss"

type Props = {
    isHidden: boolean,

    modalProps?: IModalProps,
    dialogContentProps?: IDialogContentProps,

    onDismiss?: Function;

    children?: string | ReactElement;
    footerChildren?: ReactNode;
    maxWidth?: number
}

export default function Dialog(props: Props) {
    const {
        dialogContentProps,
        onDismiss,
        isHidden: isHiddenProps,
        modalProps,
        children,
        footerChildren,
        maxWidth
    } = props;

    const [isHidden, setIsHidden] = useState(isHiddenProps);

    const onDissmissChange = useCallback(() => {
        if (onDismiss) {
            onDismiss();
            return;
        }
        setIsHidden(true);
    }, [onDismiss])

    useEffect(() => {
        setIsHidden(isHiddenProps);
    }, [isHiddenProps])

    return (
        <>
            <DialogFluent
                hidden={isHidden}
                onDismiss={onDissmissChange}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
                maxWidth={maxWidth}
            >
                {children}
                
                {footerChildren && (
                    <DialogFooter>
                        {footerChildren}
                    </DialogFooter>
                )}

            </DialogFluent>
        </>
    )
}